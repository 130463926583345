import React from "react";
import PropTypes from "prop-types";

import { GoogleLogin } from "react-google-login";
import { useStoreState, useStoreActions } from "easy-peasy";

import * as Icons from "../Icons";

function Auth(props) {
  const isAuthed = useStoreState((state) => state.isAuthed);
  const setIsAuthed = useStoreActions((actions) => actions.setIsAuthed);
  const updateConfig = useStoreActions((actions) => actions.updateConfig);

  const uid = useStoreState((state) => state.uid);
  const baseUrl = useStoreState((state) => state.baseUrl);
  const apiToken = useStoreState((state) => state.apiToken);
  const logger = useStoreActions((actions) => actions.logger);

  // Depending on where the component is rendered (onboarding vs /app), show or hide the skip button.
  const canSkip = props.canSkip;
  const theme = props.theme || "light";
  const color1 = theme == "light" ? "sky-500" : "blueGray-600";
  const color2 = theme == "light" ? "white" : "white";

  /**
   * Called after Google auth is successful (but we haven't hit our server, yet.)
   * The request sent to the backend includes, if exists, the anonUserUid (sent as a header
   * and used to validate whether a user already exists, locally) – and then link it to the
   * Google authed user, or not (and so create a new account with the data gathered from Google.)
   *
   * Given the onboarding flow asks the user to create a voice note, the assumption is
   * all users will be anonymous, with a locally generated ID, before they authenticate.
   */
  const responseGoogleSuccess = (response) => {
    // TODO: Remove this before prod.
    console.log(response);
    console.log(response.accessToken);

    var token = response.accessToken;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${response.accessToken}`,
        "Content-Type": "application/json",
        access_token: `${response.accessToken}`,
        user_hash: uid,
      },
      body: JSON.stringify(token),
    };

    return fetch(
      `${baseUrl}/api/v3/auth/request?api_token=${apiToken}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // TODO: use this! Should likely be a cookie?
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("user_hash", data.user_hash);
        if (data.user_hash !== uid) {
          logger({
            event: "DUPLICATE_USER_LINKED",
            properties: { oldUid: uid, newUid: data.user_hash },
          });
          updateConfig({ user_hash: data.user_hash });
        }
        setIsAuthed(true);
        logger({
          event: "ONBOARDING_STEP_COMPLETED",
          properties: { step: 3 },
        });
        document.location = "/app/library";
      })
      .catch((error) => {
        logger({
          event: "ONBOARDING_ERROR",
          properties: { step: 3, message: error.message },
        });
        alert("There was an error logging you in.");
      });
  };

  // Called after Google auth is unsuccessful.
  const responseGoogleFailure = (response) => {
    logger({
      event: "ONBOARDING_ERROR_G_AUTH",
      properties: { step: 3, message: response },
    });
  };

  return (
    <div
      className={`flex flex-col items-center ${
        props.className ? props.className : ""
      }`}
    >
      <GoogleLogin
        clientId="165882475704-sikkac955pq8ua0tvsbp5a5mle3edjns"
        buttonText="Sign in using your Google account"
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleFailure}
        cookiePolicy={"single_host_origin"}
        // isSignedIn={true} // call onSuccess callback on load to keep the user signed in.
        render={(renderProps) => (
          <button
            className={`onboarding-authg-button tracking-wider bg-${color1}
        hover:bg-sky-700
        focus:outline-none focus:ring-2 focus:ring-${color1} focus:ring-opacity-50
        shadow py-2 px-4 rounded text-${color2} flex`}
            onClick={() => {
              logger({
                event: "AUTH_G_BTN_CLICK",
              });

              renderProps.onClick();
            }}
            disabled={renderProps.disabled}
          >
            <span>{Icons.GOOGLE_ICON}</span>
            Sign in using Google
          </button>
        )}
      />

      {canSkip ? (
        <button
          className="onboarding-authskip-button flex mt-8 tracking-wider text-sm text-gray-500 hover:border-b"
          onClick={() => {
            logger({
              event: "AUTH_SKIP_BTN_CLICK",
            });
            document.location = "/app/library";
          }}
        >
          Skip and continue without creating an account
        </button>
      ) : null}
    </div>
  );
}

Auth.propTypes = {
  setCompleted: PropTypes.func,
  error: PropTypes.func,
  canSkip: PropTypes.bool,
  className: PropTypes.string,
};

export default Auth;
