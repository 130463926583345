import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useStoreState, useStoreActions } from "easy-peasy";
import { Link, useLocation } from "react-router-dom";

import * as Icons from "../Icons";
import tacLogo from "../../images/tac-logo-v23.png";
import anonProfilePic from "../../images/tac-anon-profile-pic.png";

function Sidebar(props) {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const user = useStoreState((state) => state.user);
  const configLoaded = useStoreState((state) => state.configLoaded);
  const fetchUserData = useStoreActions((actions) => actions.fetchUserData);
  const signout = useStoreActions((actions) => actions.signout);

  const activeLinkClass = "active text-gray-700 bg-gray-200";
  const defaultLinkClass =
    "text-gray-600 transition-colors duration-200 transform hover:bg-gray-200 hover:text-gray-700";
  const location = useLocation();

  const getLinkClass = (str) => {
    return location.pathname.includes(str) ? activeLinkClass : defaultLinkClass;
  };

  // Given the Sidebar is a shared component across all app pages (minus onboarding), we'll trust it
  // to load the initial user data and tracks.
  useEffect(() => {
    // Security redirect if a user isn't logged in.
    if (configLoaded && !isLoggedIn) document.location = "/app";

    if (configLoaded) fetchUserData();
  }, [configLoaded]);

  return (
    <div id="sidebar" className="fixed w-64 h-screen py-3 bg-white border-r">
      <Link to="/app" className="flex justify-center border-b pb-4">
        <img src={tacLogo} alt="" className="w-52" />
      </Link>
      <div className="flex flex-col items-center mt-6 -mx-2 border-b pb-4">
        {/* <img
          className="object-cover w-16 h-16 mx-2 rounded-full"
          src={user.profile_pic || anonProfilePic}
          alt="avatar"
        /> */}
        <h4 className="mx-2 mt-2 font-medium text-gray-800 hover:underline">
          {user.first_name} {user.last_name}
        </h4>
        <p className="mx-2 mt-1 text-sm font-medium text-gray-600 hover:underline">
          {user.email}
        </p>
      </div>

      <div className="flex flex-col justify-between flex-1 mt-6">
        <nav className="">
          <Link
            className={`dashboard-library-link flex items-center px-4 py-2 hover:no-underline ${getLinkClass(
              "library"
            )}`}
            to="/app/library"
          >
            {Icons.LIBRARY}
            <span className="mx-4 font-medium">Library</span>
          </Link>
          <Link
            className={`dashboard-account-link flex items-center px-4 py-2 mt-5 hover:no-underline ${getLinkClass(
              "account"
            )}`}
            to="/app/account"
          >
            {Icons.ACCOUNT}
            <span className="mx-4 font-medium">Account</span>
          </Link>
          <Link
            className={`dashboard-subscription-link flex items-center px-4 py-2 mt-5 hover:no-underline ${getLinkClass(
              "subscription"
            )}`}
            to="/app/subscription"
          >
            {Icons.SUBSCRIPTION}
            <span className="mx-4 font-medium">Subscription</span>
          </Link>
          <Link
            className={`dashboard-settings-link flex items-center px-4 py-2 mt-5 hover:no-underline ${getLinkClass(
              "settings"
            )}`}
            to="/app/settings"
          >
            {Icons.SETTINGS}
            <span className="mx-4 font-medium">Settings</span>
          </Link>
          <Link
            className={`dashboard-signout-link flex items-center px-4 py-2 mt-5 hover:no-underline ${getLinkClass(
              "signout"
            )}`}
            to="#"
            onClick={signout}
          >
            {Icons.SIGN_OUT}
            <span className="mx-4 font-medium">Sign out</span>
          </Link>
        </nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
