import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useStoreState, useStoreActions } from "easy-peasy";

function Pin(props) {
  const isPinned = useStoreState((state) => state.isPinned);
  const setIsPinned = useStoreActions((actions) => actions.setIsPinned);
  const logger = useStoreActions((actions) => actions.logger);

  useEffect(() => {
    return () => {
      // Faking pinned action (after we navigate away from Step0 / component is unloaded),
      // until we start using Manifest V3 with the Actions API.
      setIsPinned(isPinned);
      logger({
        event: "ONBOARDING_STEP_COMPLETED",
        properties: { step: 0 },
      });
    };
  }, []);

  return <div></div>;
}

Pin.propTypes = {};

export default Pin;
