import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import Onboarding from "./Onboarding";
import Install from "./Install";
import Library from "./Library";
import Account from "./Account";
import Subscription from "./Subscription";
import Settings from "./Settings";
import LoggedOut from "./LoggedOut";
import NotFound from "./NotFound";
// ui parts
import Sidebar from "./ui/Sidebar";
import Footer from "./ui/Footer";

import { useStoreState } from "easy-peasy";

/**
 * - Handle app routing and navigation.
 */
export default function App(props) {
  const configLoaded = useStoreState((state) => state.configLoaded);
  const isFooterShown = useStoreState((state) => state.isFooterShown);
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);

  // This has the redirection logic. First, the paths that can be bypassed (e.g., /app if use is logged in); then, the wrong paths, e.g, /onboarding.
  useEffect(() => {
    if (configLoaded && isLoggedIn && document.location.pathname == "/app/") {
      document.location = "/app/library";
    } else if (document.location.pathname == "/app/onboarding/") {
      document.location = "/app/onboarding/step1";
    }

    return () => {};
  }, [configLoaded]);

  return (
    <Router>
      <Switch>
        <Route exact path="/app">
          {configLoaded && !isLoggedIn ? <LoggedOut /> : null}
        </Route>
        <Route exact path="/app/onboarding/">
          {"Loading ..."}
        </Route>
        <Route path="/app/onboarding/:step" component={Onboarding} />
        <Route path="/app/install" component={Install} />
        <Route path="/app/library">
          <div className="flex flex-row w-full flex-grow">
            <Sidebar />
            <Library />
          </div>
        </Route>
        <Route path="/app/account">
          <div className="flex flex-row w-full flex-grow">
            <Sidebar />
            <Account />
          </div>
        </Route>
        <Route path="/app/subscription">
          <div className="flex flex-row w-full flex-grow">
            <Sidebar />
            <Subscription />
          </div>
        </Route>
        <Route path="/app/settings">
          <div className="flex flex-row w-full flex-grow">
            <Sidebar />
            <Settings />
          </div>
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      {isFooterShown ? <Footer /> : null}
    </Router>
  );
}

App.propTypes = {};
