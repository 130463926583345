import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";

function Install(props) {
  const logger = useStoreActions((actions) => actions.logger);

  return (
    <div>
      We haven't detected Talk&Comment in this browser. You need to install the
      app before going further ...
    </div>
  );
}

Install.propTypes = {};

export default Install;
