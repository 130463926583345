import React from "react";

function NotFound(props) {
  return (
    <div className="flex flex-grow items-center">
      <h1 className="text-5xl">404.</h1>
      What you're looking for
      <br />
      couldn't be found anymore.
      <br />
      Maybe it doesn't
      <br />
      want to be found. <br />
      Or you should've
      <br />
      searched before.
    </div>
  );
}

NotFound.propTypes = {};

export default NotFound;
