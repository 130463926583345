import React, { useEffect } from "react";

function Account(props) {
  return (
    <div className="flex flex-col flex-grow mt-8 mb-10  self-center items-center">
      <div className="wrapper max-w-lg">
        <div>User account thingy</div>
        <div>
          The user can edit their first and last name, as well as their profile
          picture (later), claim their unique username, view their GAuth status
          (linked or not), and be able to authenticate if anonymous right now.
        </div>
      </div>
    </div>
  );
}

Account.propTypes = {};

export default Account;
