import React, { useState } from "react";
import PropTypes from "prop-types";

import { useStoreActions, useStoreState } from "easy-peasy";

const URL_MATCHER = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
);

function FirstVoiceNote(props) {
  const isNoteMade = useStoreState((state) => state.isNoteMade);
  const setIsNoteMade = useStoreActions((actions) => actions.setIsNoteMade);
  const logger = useStoreActions((actions) => actions.logger);

  const [tries, setTries] = useState([
    // "normal text",
    // "http://localhost.com/p/41cedc36aeca68c45b47d494",
    // "http://localhost.com/p/41cedc36aeca68c45b47d494 dsdsds",
    // "https://localhost.com/p/d9eb2b4bfcbfaaa93650e247 (voice note)"
  ]);
  const [focusedOnNoteTestInput, setFocusedOnNoteTestInput] = useState(false);
  const [tryInputText, setTryInputText] = useState("");

  return (
    <section>
      <div className="mt-4 text-sm bg-white shadow">
        {tries.map((el, i) => {
          let matches;
          if ((matches = el.match(URL_MATCHER)))
            return (
              <p
                key={i}
                dangerouslySetInnerHTML={{
                  __html: el.replace(
                    matches[0],
                    `<a class="text-sky-500 underline" href="${matches[0]}">${matches[0]}</a>`
                  ),
                }}
              ></p>
            );
          return (
            <p className="p-4 border-b-2 border-opacity-50" key={i}>
              {el}
            </p>
          );
        })}
      </div>
      <div
        className={`ring-offset-1 border-2 ${
          focusedOnNoteTestInput
            ? "outline-none border-sky-500 border-opacity-50"
            : null
        }`}
      >
        <input
          type="text"
          className="px-4 py-2 rounded w-96 focus:outline-none"
          placeholder="Paste your recording here when done"
          value={tryInputText}
          onFocus={(e) => {
            setFocusedOnNoteTestInput(true);
          }}
          onBlur={(e) => {
            setFocusedOnNoteTestInput(false);
          }}
          onChange={(e) => {
            setTryInputText(e.target.value);
          }}
        />
        <button
          className="onboarding-try-button tracking-wider bg-sky-500
      hover:bg-sky-700
      disabled:bg-gray-100
      disabled:hover:bg-gray-100
      disabled:text-gray-400
      focus:outline-none
      shadow py-2 px-4 rounded-l-none text-sky-50"
          disabled={!tryInputText}
          onClick={() => {
            if (tryInputText.match(URL_MATCHER)) {
              setIsNoteMade(true);
              logger({
                event: "ONBOARDING_STEP_COMPLETED",
                properties: { step: 2 },
              });
            }
            setTries([...tries, tryInputText]);
            setTryInputText("");
          }}
        >
          Try!
        </button>
      </div>
    </section>
  );
}

FirstVoiceNote.propTypes = {};

export default FirstVoiceNote;
