import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

import Auth from "./onboarding/Auth";
import tacLogo from "../images/tac-logo-v23.png";
import chromeStore from "../images/home--chrome-download-v2.png";

function LoggedOut(props) {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const isChrome = useStoreState((state) => state.isChrome);
  const isExtensionInstalled = useStoreState(
    (state) => state.isExtensionInstalled
  );

  let section2;
  if (!isChrome) {
    section2 = (
      <p className="text-sm">
        Unfortunately, Talk&Comment's recording functionality is only available
        on Google Chrome at this time. Please use a Chrome browser or{" "}
        <a href="mailto:support@talkandcomment.com" className="underline">
          drop us a message
        </a>{" "}
        if you'd like us to support your browser.
      </p>
    );
  } else if (isChrome && !isExtensionInstalled) {
    section2 = (
      <div>
        <p className="mb-4 text-sm">
          Start by downloading the Chrome extension from the Chrome Web Store,
          and you'll be guided to use it.
        </p>
        <a
          href="https://chrome.google.com/webstore/detail/talk-and-comment-voice-no/djnhkfljnimcpelfndpcjcgngmefaobl?utm_source=loggedout"
          target="_blank"
        >
          <img
            src={chromeStore}
            className="hover:opacity-95 hover:shadow-md transition-all"
            alt="Download Talk&Comment from the App Store"
          />
        </a>
      </div>
    );
  } else {
    // is chrome, has the extension. Probably never used it. Start onboarding!
    section2 = (
      <p>
        It seems like you already have Talk&Comment installed.{" "}
        <Link to="/app/onboarding/step1" className="underline font-bold">
          Start your onboarding and learn how to use it!
        </Link>
      </p>
    );
  }

  return (
    <div className="flex flex-grow flex-col justify-center items-center">
      <div className="flex my-12 md:mb-24 text-center">
        <img src={tacLogo} alt="" className="w-52" />
      </div>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex flex-col border-blueGray-500 mt-12 md:mt-0 md:mr-16 justify-center content-center">
          <h1 className="text-xl font-light text-blueGray-500 self-center mb-8 text-center">
            Returning user?
          </h1>
          <Auth canSkip={false} theme="dark" />
        </div>
        <div className="flex flex-col md:ml-16 text-blueGray-500 bg-white px-3 md:px-6 py-12 md:py-12 w-96 md:h-96 rounded-md shadow-2xl justify-center self-center text-center border-t-4">
          <h1 className="text-xl font-light text-blueGray-500 self-center mb-8 text-center">
            New here?
          </h1>
          {section2}
        </div>
      </div>
    </div>
  );
}

LoggedOut.propTypes = {};

export default LoggedOut;
