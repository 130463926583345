// Run this example by adding <%= javascript_pack_tag 'onboarding' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";

import Pin from "./onboarding/Pin";
import Auth from "./onboarding/Auth";
import FirstVoiceNote from "./onboarding/FirstVoiceNote";
import Mic from "./onboarding/Mic";
import * as Icons from "./Icons";

import tacLogoSplash from "../images/tac-logo-v23.png";
import onboardingPinningImage from "../images/onboarding-pinning.jpg";
import onboardingRecordingImage from "../images/onboarding-recording.jpg";
import onboardingMicallowImage from "../images/onboarding-micallow.jpg";
import { useHistory, useParams } from "react-router-dom";

function Onboarding(props) {
  // /app/onboarding/:step
  // this will follow the pattern 'stepN'
  let { step } = useParams() || "1";
  step = step.match(/\d/) && step.match(/\d/)[0];

  let history = useHistory();

  const isPinned = useStoreState((state) => state.isPinned);
  const isMicGranted = useStoreState((state) => state.isMicGranted);
  const isNoteMade = useStoreState((state) => state.isNoteMade);
  const isAuthed = useStoreState((state) => state.isAuthed);

  const [isSplashShown, setIsSplashShown] = useState(false);

  const currentOnboardingPage = useStoreState(
    (state) => state.currentOnboardingPage
  );
  const setCurrentOnboardingPage = useStoreActions(
    (actions) => actions.setCurrentOnboardingPage
  );
  const hideFooter = useStoreActions((actions) => actions.hideFooter);

  const logger = useStoreActions((actions) => actions.logger);

  const TUTORIAL = [
    {
      id: 0,
      helpText: "Pin the app for easier access, from anywhere.",
      completedText: "Pin the app for easier access, from anywhere.",
      image: onboardingPinningImage,
      completedImage: onboardingPinningImage,
      isCompleted: isPinned,
      component: <Pin />,
    },
    {
      id: 1,
      helpText: "Allow your mic before you can start recording.",
      completedText: "Go to the next step to make your first voice note.",
      image: onboardingMicallowImage,
      completedImage: null,
      isCompleted: isMicGranted,
      component: <Mic />,
    },
    {
      id: 2,
      helpText:
        "Follow these steps to make your first recording. Paste the recording link below once you're done!",
      completedText: `Congrats! You made your first recording 👍 
        It will show up like this anywhere on the web. Click here to listen.`,
      image: onboardingRecordingImage,
      completedImage: null,
      isCompleted: isNoteMade,
      component: <FirstVoiceNote />,
    },
    {
      id: 3,
      helpText:
        "To save all your information and access your recordings, sign in using your Google account.",
      completedText: "You're all set! Enjoy using Talk&Comment.",
      image: null,
      completedImage: null,
      isCompleted: isAuthed,
      component: <Auth className="mt-8" canSkip={true} />,
    },
  ];

  useEffect(() => {
    // The index in the Tutorial object is always lagging by 1.
    let landingPageId = parseInt(step) - 1;

    // Check if the URL is valid, before proceeding to the rest of the logic.
    if (landingPageId < 0 || landingPageId > TUTORIAL.length - 1) {
      console.log("invalid onboarding step");
      document.location = "/app";
    } else {
      setCurrentOnboardingPage(landingPageId);
      // not using the currentOnboardingPage state here as we're not guaranteed the state was already
      // updated before the below is called.
      goToPage(landingPageId);

      // This is triggered at first render (should happen only once); if the initial page isn't
      // 0, that means the user is LIKELY coming from the extensions's #step2 micallow.
      if (landingPageId !== 0) {
        setIsSplashShown(true);
        logger({
          event: "ONBOARDING_DIRECT_LANDING",
          properties: { step: currentOnboardingPage },
        });
      }
    }
    // We don't want the footer to be visible during the onboarding flow.
    hideFooter();
    return () => {};
  }, []);

  /**
   *
   * i = 0: pinning
   * i = 1: mic allow
   * i = 2: test voice note
   * i = 3: auth
   */
  const goToPage = (i) => {
    history.push(`/app/onboarding/step${i + 1}`);
    logger({
      event: "ONBOARDING_PAGE_VIEW",
      properties: { step: i },
    });

    setCurrentOnboardingPage(i);
  };

  const nextPage = () => {
    goToPage(currentOnboardingPage + 1);
  };

  let imageSrc = null;
  TUTORIAL[currentOnboardingPage].isCompleted
    ? (imageSrc = TUTORIAL[currentOnboardingPage].completedImage)
    : null;
  !TUTORIAL[currentOnboardingPage].isCompleted
    ? (imageSrc = TUTORIAL[currentOnboardingPage].image)
    : null;

  return (
    <section id="onboarding">
      {!isSplashShown ? (
        <div className="flex splash flex-col">
          <h1 className="text-2xl text-sky-500 self-center">
            Welcome to
            <img
              src={tacLogoSplash}
              alt=""
              className="w-96 mt-2 animate-splash"
            />
          </h1>
          <h3 className="text-md text-gray-700 mt-6 max-w-xl">
            With talk&comment, you can record yourself and create voice notes in
            your favorite apps and services, from inside your browser!
          </h3>
          <button
            className="splash-continue-button flex flex-initial self-end mt-16 tracking-wider bg-sky-500
          hover:bg-sky-700
          focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50
          shadow py-2 px-4 rounded text-sky-50"
            style={{ animationDelay: "2s" }}
            onClick={() => {
              setIsSplashShown(true);
            }}
          >
            Continue <span>{Icons.CHEVRON_RIGHT}</span>
          </button>
        </div>
      ) : (
        <div className="guide-wrapper flex flex-col items-center">
          <nav className="flex self-center space-x-3">
            {TUTORIAL.map((el, i) => {
              return (
                <h2
                  key={i}
                  className={`py-2 flex flex-row ${
                    i === currentOnboardingPage
                      ? "text-sky-500"
                      : "text-gray-300"
                  }`}
                >
                  <a href="#" onClick={() => goToPage(i)}>
                    {TUTORIAL[i].isCompleted ? (
                      <span>{Icons.FILLED_CIRCLE}</span>
                    ) : (
                      <span>{Icons.EMPTY_CIRCLE}</span>
                    )}
                  </a>
                </h2>
              );
            })}
          </nav>
          <section
            className="flex flex-col items-center justify-center mt-4 p-8 bg-white rounded-md shadow-2xl"
            style={{ minHeight: "460px", width: "600px" }}
          >
            {currentOnboardingPage === 0 ? Icons.ARROW_UP : null}
            {imageSrc ? (
              <img
                className="w-96 shadow-md"
                src={imageSrc}
                alt="onboarding image"
              />
            ) : null}
            <p className="text-lg mt-6">
              {TUTORIAL[currentOnboardingPage].isCompleted
                ? TUTORIAL[currentOnboardingPage].completedText
                : TUTORIAL[currentOnboardingPage].helpText}
            </p>
            {TUTORIAL[currentOnboardingPage].component}
          </section>
          <footer>
            {/* Only show the "Next" button if it's the optional first
          step (pinning ext), or if the step is completed. */}
            {TUTORIAL.length > currentOnboardingPage + 1 &&
            (currentOnboardingPage === 0 ||
              TUTORIAL[currentOnboardingPage].isCompleted) ? (
              <button
                className="onboarding-continue-button flex mt-8 tracking-wider bg-sky-500
          hover:bg-sky-700
          focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50
          shadow py-2 px-4 rounded text-sky-50"
                onClick={nextPage}
              >
                Continue <span>{Icons.CHEVRON_RIGHT}</span>
              </button>
            ) : // TODO: the user should basically get to their account page at this step.
            null}
          </footer>
        </div>
      )}
    </section>
  );
}

Onboarding.defaultProps = {};

Onboarding.propTypes = {};

export default Onboarding;
