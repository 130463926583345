import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useStoreState, useStoreActions } from "easy-peasy";
import * as Icons from "./../Icons";

function Mic(props) {
  const isMicGranted = useStoreState((state) => state.isMicGranted);
  const setIsMicGranted = useStoreActions((actions) => actions.setIsMicGranted);
  const logger = useStoreActions((actions) => actions.logger);

  const [insertedMicIframe, setInsertedMicIframe] = useState(false);
  const extId = useStoreState((state) => state.extId);

  useEffect(() => {
    // listen for when mic is granted, from the iframe.
    // chrome/src/js/permissions.js triggers this.
    // Should only be called during the app's lifecycle, and not when the user first lands here.
    // contrary to the below CRX call, that's triggered at launch.
    window.addEventListener(
      "message",
      (event) => {
        var granted;
        if (event.origin.match(extId)) {
          granted = event.data.accessGranted ? true : false;
          setIsMicGranted(granted);
          if (granted)
            logger({
              event: "ONBOARDING_STEP_COMPLETED",
              properties: { step: 1 },
            });
        }
      },
      false
    );

    // TODO: Maybe not needed given config is loaded on first init?
    // Leaving it for now as not everything is e2e tested.
    // Check if mic is granted on component first load (ask the background page)
    try {
      chrome.runtime.sendMessage(
        extId,
        { text: "isMicGranted" },
        (response) => {
          if (response.message == "granted") {
            setIsMicGranted(true);
          }
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      return () => {
        window.removeEventListener("message", () => {
          return false;
        });
      };
    }
  }, []);

  const grantMicAccess = () => {
    if (!insertedMicIframe) {
      setInsertedMicIframe(true);
      const el = document.createElement("div");
      el.innerHTML = `<iframe allow="microphone" style="display: none"
      src="chrome-extension://${extId}/permissions.html">`;
      document.body.appendChild(el);
      return true;
    }
    return false;
  };

  return !isMicGranted ? (
    <div>
      <button
        className="onboarding-mic-permission-button flex mt-8 tracking-wider bg-white
      hover:bg-sky-500 hover:text-white
      focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50
      shadow py-2 px-4 rounded text-sky-500 border-2 border-sky-500"
        onClick={() => {
          grantMicAccess();
        }}
      >
        <span className="mt-0 mr-2">{Icons.MICROPHONE}</span> Request Microphone
        Permission
      </button>
    </div>
  ) : (
    <div></div>
  );
}

Mic.propTypes = {};

export default Mic;
