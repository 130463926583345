import React from "react";

function Footer() {
  return (
    <footer className="p-5 mt-16 flex self-center items-center text-gray-500 border-t w-full justify-center text-sm">
      <nav className="">
        <ul className="flex flex-auto flex-col space-y-3 md:flex-row md:space-x-8 md:space-y-0">
          <li>
            <a href="/" target="_blank" className="text-gray-500">
              Home
            </a>
          </li>
          <li>
            <a href="/legal/privacy" target="_blank" className="text-gray-500">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/legal/tos" target="_blank" className="text-gray-500">
              Terms of Service
            </a>
          </li>
          <li>
            <a
              href="mailto:support@talkandcomment.com"
              className="text-gray-500"
            >
              Contact
            </a>
          </li>
          <li>
            <span className="ml-10">Talk&Comment &copy; 2021</span>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
