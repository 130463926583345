import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { Link } from "react-router-dom";

function Library(props) {
  const isLoading = useStoreState((state) => state.isLoading);
  const tracksPage = useStoreState((state) => state.tracksPage);
  const tracksTotal = useStoreState((state) => state.tracksTotal);
  const tracksList = useStoreState((state) => state.tracksList);
  const fetchUserData = useStoreActions((actions) => actions.fetchUserData);
  const setIsLoading = useStoreActions((actions) => actions.setIsLoading);

  useEffect(() => {
    setIsLoading(false);
    return () => {};
  }, [tracksList]);

  return (
    <div className="flex flex-col flex-grow mt-8 mb-10 justify-center items-center">
      {tracksList.length > 0 ? (
        <div id="tracks" className="flex flex-col">
          <p className="text-xs text-gray-700 text-center mb-4">
            Showing {tracksList.length} out of {tracksTotal}
          </p>
          <ul className="flex flex-col">
            {tracksList.map((track, i) => (
              <li key={i} className="track mb-6">
                <div className="max-w-2xl px-8 py-4 mx-auto bg-white rounded-lg shadow-md">
                  <p className="text-sm mt-2">
                    Created {moment(track.created_at).fromNow()}
                  </p>
                  <audio controls>
                    <source src={track.file_url} type="audio/webm" />
                    <source
                      src={`https://cdn2.talkandcomment.com/mp4/uploads/${track.id}.mp4`}
                      type="audio/aac"
                    />
                  </audio>
                  <p className="text-sm mt-2">
                    Created in{" "}
                    <a
                      className="text-sky-500"
                      href={track.source}
                      target="_blank"
                    >
                      {track.source}
                    </a>
                  </p>
                  <p className="text-xs">Recording {track.id}</p>
                </div>
              </li>
            ))}
          </ul>
          <div id="tracks-loader" className="flex self-center items-center">
            {tracksList.length < tracksTotal ? (
              <button
                className="mt-8 tracking-wider bg-sky-500
        hover:bg-sky-700
        disabled:bg-gray-100
        disabled:hover:bg-gray-100
        disabled:text-gray-400  
        focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50
        shadow py-2 px-4 rounded text-sky-50"
                onClick={() => {
                  setIsLoading(true);
                  fetchUserData(tracksPage + 1);
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading ..." : "Show more"}
              </button>
            ) : !isLoading ? (
              <p className="text-xs text-gray-500">
                All {tracksTotal} recordings displayed.
              </p>
            ) : null}
          </div>
        </div>
      ) : isLoading ? (
        "Loading ..."
      ) : (
        <div>
          <p>
            Start by making your first TAC recording! <br />{" "}
            <Link
              to="/app/onboarding/step1"
              className="dashboard-onboarding-link"
            >
              Learn how.
            </Link>
          </p>
        </div>
      )}
    </div>
  );
}

Library.propTypes = {};

export default Library;
