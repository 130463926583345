import React from "react";

function Subscription(props) {
  return (
    <div className="flex flex-col flex-grow mt-8 mb-10  self-center items-center">
      <div className="wrapper max-w-lg">
        <div>User subscription</div>
        <div>pick a plan, pay, etc.</div>
      </div>
    </div>
  );
}

Subscription.propTypes = {};

export default Subscription;
