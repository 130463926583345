import React from "react";

function Settings(props) {
  return (
    <div className="flex flex-col flex-grow mt-8 mb-10  self-center items-center">
      <div className="wrapper max-w-lg">
        <div>User settings</div>
        <div>Settings for the extension, select mic, etc.</div>
      </div>
    </div>
  );
}

Settings.propTypes = {};

export default Settings;
